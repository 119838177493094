body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bold {
  font-weight: 600;
}

.custom-datepicker {
  width: 9em;
}

.red-text {
  color: rgb(233, 7, 7);
  font-weight: bold;
}

.cursor {
  cursor: pointer;
}

.createdAtReport {
  font-size: 0.75em;
}

.vertical-line {
  border-left: 1px solid #a5a5a5; /* Cor e espessura da linha */
  height: 1em; /* Altura da linha */
  margin-left: 2px; /* Espaço à esquerda da linha */
  align-self: center;
}

.btn:active {
  background-color: inherit;
  color: inherit;
}

.form-check-input {
  cursor: pointer;
}