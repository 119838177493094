@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");

* {
  font-family: "Montserrat", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #ccc;
}

.sidebar-content a {
  text-transform: capitalize;
}

.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  color: #fff;

  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.scroll-to-top-button.visible {
  display: block;
  opacity: 1;
}
