.late-class {
  color: rgb(233, 7, 7);
  font-weight: bold;
}

.car-service-control {
  border: 0.5px solid rgb(146, 146, 146);
  border-radius: 5px;
  text-align: center;
  padding: 1.25em 1em;
  margin-bottom: 15px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.license-plate {
  display: inline-block;
  padding: 10px;
  padding-bottom: 0;
  border: 2px solid #333;
  background-color: #fff;
  color: #333;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  position: relative;
}

.license-plate::before {
  content: "BRASIL";
  font-size: 0.4em;
  color: white;
  position: absolute;
  top: -1px; /* Ajuste para posicionar acima do .license-plate */
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 12px;
  background-color: blue;
  z-index: 1; /* Garante que a linha esteja acima do conteúdo do .license-plate */
}
@media (max-width: 920px) {
  .license-plate::before {
    content: "BRASIL 🇧🇷";
  }
}

.vehicleDescription {
  font-size: 1em;
  min-height: 2.25em;
}

.estimatedDate {
  font-size: 0.85em;
}
